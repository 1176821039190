// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer.tsx");
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
import styles from './Footer.module.css';
import { Text } from './Text';
const links = ['tsAndCs', 'privacyPolicy', 'cookiePolicy'];
export function Footer() {
  _s();
  const {
    t
  } = usePrefixedTranslation('common');
  const year = new Date().getFullYear();
  return <footer className={styles.footer}>
      {links.map(key => {
      const url = t(`${key}.url`);
      return url ? <Link to={url} target="_blank" key={key}>
            <Text size="sm" as="span">
              {t(`${key}.text`)}
            </Text>
          </Link> : null;
    })}

      <Text size="sm" as="p" className={styles.copyright}>
        {t('copyright', {
        year
      })}
      </Text>
    </footer>;
}
_s(Footer, "YbpGSBOvx+UXrbc9uATeO7+5K1M=", false, function () {
  return [usePrefixedTranslation];
});
_c = Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;