// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Maintenance.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Maintenance.tsx");
  import.meta.hot.lastModified = "1731405470890.4155";
}
// REMIX HMR END

import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
import { ErrorPage } from './ErrorPage';
import { Text } from './Text';
export function Maintenance() {
  _s();
  const {
    t
  } = usePrefixedTranslation('error');
  return <ErrorPage title={t('maintenance.title')}>
      <Text as="p" size="lg" weight={500}>
        {t('maintenance.message')}
      </Text>

      <Text as="p" size="lg">
        {t('maintenance.subscribeOnAppStore')}
      </Text>
    </ErrorPage>;
}
_s(Maintenance, "YbpGSBOvx+UXrbc9uATeO7+5K1M=", false, function () {
  return [usePrefixedTranslation];
});
_c = Maintenance;
var _c;
$RefreshReg$(_c, "Maintenance");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;